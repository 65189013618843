<template>
  <v-navigation-drawer
  
    v-model="value"
    @input="$emit('input', $event)"
    :mini-variant.sync="value"
    color="secondary"
    dark
    app
    fixed
    permanent
  >
      <v-list-item class="px-2 py-1 ligth-grey" dark>
      <v-list-item-avatar>
        <v-img width="20%" src="/logoAmer.svg" contain></v-img>
      </v-list-item-avatar>

      <v-list-item-title class="title white--text">AMER</v-list-item-title>
    </v-list-item>

    <v-divider></v-divider>

    <v-list dense class="pt-0" nav>
      <template v-for="item in items">
        <v-list-group
          v-if="item.childrens && item.show"
          :prepend-icon="item.icon"
          :key="item.title"
          no-action
          active-class="primary"
          class="white--text"
          color="#fff"
        >
          <template v-slot:activator>
            <v-list-item-title class="white--text">{{
              item.title
            }}</v-list-item-title>
          </template>

          <template v-for="child in item.childrens">
            <v-list-group
              v-if="child.childrens"
              :key="child.title"
              no-action
              sub-group
              active-class="primary"
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>{{ child.title }} </v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item
                v-for="(c, i) in child.childrens"
                :key="i"
                :to="c.url"
                :exact="true"
                link
                active-class="primary"
                class="white--text "
              >
                <v-list-item-title v-text="c.title"> </v-list-item-title>

                <!-- <v-list-item-icon>
                  <v-icon v-text="icon"></v-icon>
                </v-list-item-icon> -->
              </v-list-item>
            </v-list-group>

            <v-list-item
              v-else
              :key="child.title"
              :to="child.url"
              :exact="item.exact"
              router
              active-class="primary"
              class="px-3"
              style="width: 95%; margin-left:5%"
            >
              <v-list-item>
                <v-list-item-icon>
                  <v-icon class="white--text" v-text="child.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-title v-text="child.title"></v-list-item-title>
              </v-list-item>
              <!-- <v-list-item-icon>
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-item-icon> -->

              <!-- <v-list-item-content> -->
              <!-- <v-list-item-title v-text="child.title"></v-list-item-title> -->
              <!-- </v-list-item-content> -->
            </v-list-item>
          </template>
        </v-list-group>

        <v-list-item
          v-else-if="item.show"
          :key="item.title"
          :to="item.url"
          :exact="item.exact"
          link
          active-class="primary"
          class="white--text"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";
import accessLevelMixins from "@/mixins/access-level";
export default {
  name: "AppMenu",
  props: {
    value: Boolean,
  },
  mixins: [accessLevelMixins],
  data: () => ({
    mini: true,
    // value:true,
  }),
 
  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
    }),
    items() {
      return [
        {
          title: this.$t("dashboard"),
          icon: " mdi-home",
          url: "/dashboard",
          show: this.accessLevel(this.currentUser, "dashboard"),
          exact: true,
        },
        {
          title: this.$t("postvacancy"),
          icon: "mdi-share-all",
          url: "/dashboard/entity/publish",
          show: this.accessLevel(this.currentUser, "publish"),
          exact: true,
        },
        {
          title: this.$t("vacancy"),
          icon: "mdi-check-circle",
          url: "/dashboard/entity/vacancies",
          show: this.accessLevel(this.currentUser, "vacancies_list"),
          exact: true,
        },
        {
          title: this.$t("candidancy"),
          icon: "mdi-account-group",
          url: "/dashboard/entity/candidacies",
          show: this.accessLevel(this.currentUser, "applications_list"),
        },
        {
          title: this.$t("candidaterankig"),
          icon: "mdi-account-check",
          url: "/dashboard/entity/ranking",
          show: this.accessLevel(this.currentUser, "ranking_list"),
        },
        {
          title: this.$t("interviews"),
          icon: "mdi-calendar-check",
          url: "/dashboard/entity/interviews",
          show: this.accessLevel(this.currentUser, "interviews_list"),
        },
        {
          title: this.$t("test_library"),
          icon: "mdi-clipboard-list",
          url: "/dashboard/entity/tests",
          show: this.accessLevel(this.currentUser, "tests_list"),
          exact: true,
        },
        // {
        //   title: this.$t("test_library"),
        //   icon: "mdi-clipboard-list",
        //   route: "/tests",
        //   show: this.accessLevel(this.currentUser, "tests_list"),
        //   childrens: [
        //     {
        //       title: this.$t("tests"),
        //       url: "/dashboard/entity/tests",
        //       icon: "mdi-clipboard-list",
        //     },
        //     {
        //       title: this.$t("activities"),
        //       url: "/dashboard/entity/test/activities",
        //       icon: "mdi-trending-down",
        //     },
        //   ],
        // },
        {
          title: this.$t("clients"),
          icon: "mdi-account-tie",
          url: "/dashboard/entity/clients",
          show: this.accessLevel(this.currentUser, "clients_list"),
          exact: true,
        },
        {
          title: this.$t("create_database"),
          icon: "mdi-database",
          url: "/dashboard/entity/candidates",
          show: this.accessLevel(this.currentUser, "database_list"),
        },
      ];
    },
  },
};
</script>
