<template>
  <div v-click-outside="onClickOutside">
    <v-card class="sc-chat-window" border>
      <v-toolbar color="primary" light dark dense>
        <v-avatar size="36">
          <img :src="`/avatar-whire.png`" />
        </v-avatar>
        <v-toolbar-title class="mx-2">
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title
                style="text-transform: uppercase; font-size: 10pt"
                >{{ $t("TALKS") }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-row class="d-flex flex-row justify-end mt-2 px-3">
        <v-btn text @click="back" class="btn">
          <v-icon color="black"> mdi-chevron-left</v-icon> {{ $t("Back") }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn text @click="back" color="primary" class="btn">
          {{ $t("Switchtosuport") }}
        </v-btn>
        <v-col cols="12">
          <v-text-field
            class="fieldBorder"
            v-model="search"
            solo
            :label="$t('searchbyname')"
            color="primary"
            append-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <!-- <pre> {{filteredItems}}</pre> -->
      </v-row>
      <div ref="scrollList" class="sc-message-list">
        <v-list two-line>
          <v-list-item-group v-model="selected" active-class="primary--text">
            <!-- {{loadMessages}} -->
            <template v-for="(skeleton, x) in 7">
              <v-skeleton-loader
                :key="x"
                type="list-item-avatar"
                v-if="!loadMessages"
              >
              </v-skeleton-loader>
            </template>
            <template v-for="(item, index) in filteredItems">
              <!-- <pre :key="item.action">
                  {{`${apiUrl}/images/user/${item.messages[0].to.photo}`}}
                </pre> -->
              <v-list-item
                :key="item.name"
                v-if="item.flag === 'Chat'"
                @click="initChat(item)"
              >
                <!-- <template> -->
                <template v-slot:default="{ active }">
                  <v-list-item-avatar>
                    <v-badge
                      bordered
                      bottom
                      color="primary"
                      dot
                      offset-x="14"
                      offset-y="8"
                    >
                      <v-avatar
                        size="35"
                        v-if="item.messages[0].to.id != currentUser.id"
                      >
                        <v-img
                          :src="
                            item.messages[0].to.photo
                              ? item.messages[0].to.type === 'entity'
                                ? `${apiUrl}/images/user/${item.messages[0].to.photo}`
                                : `${apiUrl}/images/partner/${item.messages[0].to.photo}`
                              : '/profile.png'
                          "
                        ></v-img>
                      </v-avatar>
                      <v-avatar size="35" v-else>
                        <v-img
                          :src="
                            item.messages[0].author.photo
                              ? `${apiUrl}/images/user/${item.messages[0].author.photo}`
                              : '/profile.png'
                          "
                        ></v-img>
                      </v-avatar>
                    </v-badge>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title
                      v-text="
                        item.messages[0].to.name === currentUser.name
                          ? getUserName(item.messages[0].author.name)
                          : getUserName(item.messages[0].to.name)
                      "
                    ></v-list-item-title>

                    <v-list-item-subtitle
                      class="text--primary"
                      v-text="item.messages[item.messages.length - 1].body"
                    ></v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-badge
                      color="red"
                      :content="'New'"
                      bordered
                      overlap
                      left
                      v-if="
                        countReads(item.messages[item.messages.length - 1]) > 0
                      "
                    >
                      <v-icon v-if="!active" color="grey" right>
                        mdi-chat
                      </v-icon>

                      <v-icon v-else color="grey"> mdi-chat </v-icon>
                    </v-badge>
                    <v-btn
                      v-else
                      block
                      outlined
                      color="primary"
                      @click="initChat(item)"
                    >
                      start
                    </v-btn>
                  </v-list-item-action>
                </template>
              </v-list-item>
              <v-divider
                v-if="index < items.length - 1"
                :key="index"
              ></v-divider>
            </template>
          </v-list-item-group>
        </v-list>
      </div>
      <v-row class="d-flex flex-row justify-end align-center mt-5 px-3">
        <v-container>
          <v-col cols="12">
            <v-container>
              <!-- <v-btn block outlined>
                Star a chat with all
              </v-btn> -->
            </v-container>
          </v-col>
        </v-container>
      </v-row>
    </v-card>
  </div>
</template>
<script>
import { CHATS_ROOM_QUERY } from "./../graphql/Query";
// import { UPDATE_MESSAGE } from "./../graphql/Mutation";
import { API_URL } from "./../../../../../api";
import replaceSpecialCharsMixins from "@/mixins/replaceSpecialChars";
import {
  MESSAGE_SENT_SUBSCRIPTION,
  // UPDATED_COUNT_CHAT,
} from "./../graphql/subscription";
import { mapGetters } from "vuex";
export default {
  mixins: [replaceSpecialCharsMixins],
  data: () => ({
    apiUrl: API_URL,
    selected: null,
    message: "",
    items: [],
    loadMessages: false,
    search: "",
  }),
  apollo: {
    recivedChats: {
      query: CHATS_ROOM_QUERY,

      fetchPolicy: "no-cache",
      result({ data }) {
        if (data.recivedChats) {
          console.log("recivedChats", data.recivedChats);
          this.items = data.recivedChats;
          this.loadMessages = true;
        }
      },
    },
    $subscribe: {
      message: {
        query: MESSAGE_SENT_SUBSCRIPTION,
        result({ data }) {
          if (data.addChat.messages.author.id != this.currentUser.id) {
            var index = this.items.findIndex(
              (u) => u.room == data.addChat.name
            );
            if (index > -1) {
              console.log("notifica", data.addChat.messages);
              this.items[index].messages.push({
                ...data.addChat.messages,
                to: { id: this.currentUser.id },
              });
              console.log("resultado", this.items[index].messages);
              this.items.reverse();
            } else {
              if (data.addChat.messages.flag_to === this.currentUser.id) {
                this.notifications = this.notifications + 1;
                this.items.push({
                  room: data.addChat.name,
                  messages: [data.addChat.messages],
                });
              }
            }
          }
        },
      },
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
    }),
    filteredItems() {
      let resultItems = this.items;
      if (this.search) {
        const text = this.replaceSpecialChars(this.search);
        resultItems = resultItems.filter((t) => {
          if (t.messages[0].to.id != this.currentUser.id) {
            return this.replaceSpecialChars(t.messages[0].to.name).includes(
              text
            );
          } else {
            return this.replaceSpecialChars(t.messages[0].author.name).includes(
              text
            );
          }
        });
      }
      return resultItems;
    },
  },

  methods: {
    countReads(messages) {
      if (messages.to.id === this.currentUser.id) {
        return messages.read;
      }
      // return messages.filter(
      //   (m) => m.read === "1" && m.to.id === this.currentUser.id
      // ).length;
    },
    back() {
      this.$emit("initList");
    },
    getUserName(name) {
      name = name.split(" ");
      if (name.length > 1) {
        return name[0] + " " + name[1];
      } else {
        return name[0];
      }
    },
    close() {
      this.$emit("close");
    },
    onClickOutside(event, el) {
      console.log(event, el);
      this.close();
    },
    capitalize(word) {
      const loweredCase = word.toLowerCase();
      return word[0].toUpperCase() + loweredCase.slice(1);
    },
    async initChat(valor) {
      console.log("valor", valor.room.substring(0, 24));

      if (valor.messages[0].to.id === this.currentUser.id) {
        valor = [{ ...valor, to: valor.messages[0].author.id }];
      } else if (valor.messages[0].author.id === this.currentUser.id) {
        valor = [{ ...valor, to: valor.messages[0].to.id }];
      }
      this.$emit("initChat", valor[0]);
    },
  },
};
</script>
<style scoped src="@/assets/css/chat_css/chatList.css"></style>
