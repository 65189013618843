import gql from 'graphql-tag'

export const ADD_VIEWS_VACANCY = gql`
  mutation AddViewsVacancy($id: ID!) {
    addViewsVacancy(id: $id)
  }
`
export const SEND_STATUS = gql `
  mutation sendStatus($user_id: ID, $description: String, $locale: String) {
    sendStatus(user_id: $user_id, description: $description, locale: $locale){
      description
      locale
      candidate
    }
  }
`