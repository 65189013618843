var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{attrs:{"fixed":"","color":"navbar","app":""}},[_c('v-app-bar-nav-icon',{on:{"click":function($event){$event.stopPropagation();return _vm.$emit('hide', !_vm.show)}}}),_c('v-spacer'),_c('div',{staticClass:"px-4"},[_c('v-spacer'),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","color":""}},on),[_c('flag',{attrs:{"iso":_vm.currentFlag,"squared":false}}),_c('v-icon',{attrs:{"right":"","color":"primary"}},[_vm._v("mdi-menu-down")])],1)]}}])},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.languagess),function(entry,index){return _c('v-list-item',{key:(index + "010"),on:{"click":function($event){return _vm.changeLocale(entry)}}},[_c('v-list-item-title',[_c('flag',{attrs:{"iso":entry.flag,"squared":false}}),_vm._v(" "+_vm._s(entry.title)+" ")],1)],1)}),1)],1),(_vm.currentUser)?_c('v-menu',{attrs:{"bottom":"","origin":"center center","transition":"scale-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":"","color":"primary"}},on),[_c('div',[(_vm.notifications)?_c('v-badge',{attrs:{"color":"red","content":_vm.notifications,"bordered":"","overlap":"","left":""}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-bell ")])],1):_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-bell ")])],1)])]}}],null,false,3300092441)},[_c('v-card',{staticClass:"mx-auto",attrs:{"max-width":"500"}},[_c('v-list',{attrs:{"two-line":""}},[_c('v-list-item',[_c('v-list-item-action',[_c('v-icon',{staticClass:"primary--text"},[_vm._v("mdi-message-text")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("messages"))+" ")]),_c('v-list-item-action',[_vm._v(" ("+_vm._s(_vm.notifications)+") ")])],1),_c('v-divider',{staticClass:"mx-3",staticStyle:{"margin-top":"-4.5%"}}),_c('v-list-item-group',{attrs:{"active-class":"primary--text"},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_vm._l((3),function(skeleton,x){return [(!_vm.loadMessages)?_c('v-skeleton-loader',{key:x,attrs:{"type":"list-item-avatar"}}):_vm._e()]}),_vm._l((_vm.chats),function(item,index){return [(
                    (item.flag === 'Suport' && _vm.countReads(item.messages) > 0) ||
                      item.flag === 'Chat'
                  )?_c('v-list-item',{key:item.name,on:{"click":function($event){item.flag === 'Chat' ? _vm.initChat(item) : _vm.initSuport()}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var active = ref.active;
return [_c('v-list-item-avatar',[_c('v-badge',{attrs:{"bordered":"","bottom":"","color":"primary","dot":"","offset-x":"15","offset-y":"11"}},[(item.messages[0].to.id != _vm.currentUser.id)?_c('v-avatar',{attrs:{"size":"35"}},[_c('v-img',{attrs:{"src":item.flag === 'Suport'
                                ? item.messages[0].to.photo
                                  ? (_vm.apiUrl + "/images/partner/" + (item.messages[0].to.photo))
                                  : '/profile.png'
                                : item.messages[0].to.photo
                                ? item.messages[0].to.type === 'entity'
                                  ? (_vm.apiUrl + "/images/user/" + (item.messages[0].to.photo))
                                  : (_vm.apiUrl + "/images/partner/" + (item.messages[0].to.photo))
                                : '/profile.png'}})],1):_c('v-avatar',{attrs:{"size":"35"}},[_c('v-img',{attrs:{"src":item.messages[0].author.photo
                                ? (_vm.apiUrl + "/images/user/" + (item.messages[0].author.photo))
                                : '/profile.png'}})],1)],1)],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(
                          item.flag === 'Suport'
                            ? item.messages[0].to.name === _vm.currentUser.name
                              ? 'Suporte WhireLab'
                              : 'Suporte WhireLab'
                            : item.messages[0].to.name === _vm.currentUser.name
                            ? _vm.getUserName(item.messages[0].author.name)
                            : _vm.getUserName(item.messages[0].to.name)
                        )}}),_c('v-list-item-subtitle',{staticClass:"text--primary",domProps:{"textContent":_vm._s(item.messages[item.messages.length - 1].body)}})],1),_c('v-list-item-action',[(_vm.countReads(item.messages) > 0)?_c('v-badge',{attrs:{"color":"red","content":_vm.countReads(item.messages),"bordered":"","overlap":""}},[(!active)?_c('v-icon',{attrs:{"color":"grey"}},[_vm._v(" mdi-chat ")]):_c('v-icon',{attrs:{"color":"grey"}},[_vm._v(" mdi-chat ")])],1):_c('v-btn',{attrs:{"block":"","outlined":"","color":"primary"}},[_vm._v(" start ")])],1)]}}],null,true)}):_vm._e(),(index < _vm.chats.length - 1)?_c('v-divider',{key:index}):_vm._e()]}),_vm._l((_vm.suportChats),function(item,index){return [(_vm.countReadsSuport(item.messages) > 0)?_c('v-list-item',{key:item.name,on:{"click":function($event){return _vm.initSuport()}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var active = ref.active;
return [_c('v-list-item-avatar',[_c('v-badge',{attrs:{"bordered":"","bottom":"","color":"primary","dot":"","offset-x":"15","offset-y":"11"}},[_c('v-avatar',{attrs:{"size":"35"}},[_c('v-img',{attrs:{"src":_vm.SuportPhoto
                                ? (_vm.apiUrl + "/images/user/" + _vm.SuportPhoto)
                                : '/profile.png'}})],1)],1)],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s('Suporte WhireLab')}}),_c('v-list-item-subtitle',{staticClass:"text--primary",domProps:{"textContent":_vm._s(item.messages[item.messages.length - 1].body)}})],1),_c('v-list-item-action',[(_vm.countReadsSuport(item.messages) > 0)?_c('v-badge',{attrs:{"color":"red","content":_vm.countReadsSuport(item.messages),"bordered":"","overlap":""}},[(!active)?_c('v-icon',{attrs:{"color":"grey"}},[_vm._v(" mdi-chat ")]):_c('v-icon',{attrs:{"color":"grey"}},[_vm._v(" mdi-chat ")])],1):_c('v-btn',{attrs:{"block":"","outlined":"","color":"primary"}},[_vm._v(" start ")])],1)]}}],null,true)},[_vm._v(" --> ")]):_vm._e(),(index < _vm.chats.length - 1)?_c('v-divider',{key:index}):_vm._e()]})],2),_c('v-list-item',[_c('v-list-item-action',[_c('v-icon',{staticClass:"primary--text"},[_vm._v("mdi-account-multiple")])],1),_c('v-list-item-title',[_vm._v("Candidaturas")]),_c('v-list-item-action',[_vm._v(" ("+_vm._s(0)+") ")])],1),_c('v-divider',{staticClass:"mx-3",staticStyle:{"margin-top":"-4.5%"}})],1)],1)],1):_vm._e(),(_vm.currentUser)?_c('v-menu',{attrs:{"bottom":"","origin":"center center","transition":"scale-transition","offset-y":"","width":"400px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":"","color":"primary"}},on),[_c('v-avatar',{attrs:{"size":"22","item":""}},[(_vm.currentUser.photo)?_c('img',{attrs:{"src":(_vm.apiUrl + "/images/user/" + (_vm.currentUser.photo))}}):_c('v-icon',{staticClass:"primary--text"},[_vm._v("mdi-account-circle")])],1)],1)]}}],null,false,3123788039)},[_c('v-card',{staticClass:"mx-auto"},[_c('v-list',{attrs:{"two-line":""}},[_c('v-list-item',[_c('v-list-item-avatar',[_c('img',{attrs:{"src":_vm.currentUser.photo
                      ? (_vm.apiUrl + "/images/user/" + (_vm.currentUser.photo))
                      : "/avatar.png"}})]),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.currentUser.name)+" ")]),_c('v-list-item-subtitle',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.currentUser.email)+" ")])],1)],1)],1),_c('v-divider'),_c('v-list',[_c('v-list-item',{attrs:{"router":"","to":"/dashboard/entity/user/profile"}},[_c('v-list-item-action',[_c('v-icon',{staticClass:"primary--text"},[_vm._v("mdi-account-circle")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("form_candidate_profile")))])],1),_c('v-list-item',{on:{"click":function($event){$event.preventDefault();return _vm.logout($event)}}},[_c('v-list-item-action',[_c('v-icon',{staticClass:"primary--text"},[_vm._v("mdi-logout-variant")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("logout")))])],1)],1)],1)],1):_vm._e(),(_vm.accessLevel(this.currentUser, 'setting_list'))?_c('v-menu',{attrs:{"bottom":"","origin":"center center","transition":"scale-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
return [_c('v-btn',{attrs:{"icon":"","small":""}},[_c('v-icon',_vm._g({attrs:{"color":"primary"}},on),[_vm._v("settings")])],1)]}}],null,false,4010636347)},[_c('v-list',[_vm._l((_vm.settings),function(setting,i){return [(setting.show)?_c('v-list-item',{key:i,attrs:{"route":"","to":setting.route,"exact":setting.exact}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(_vm._s(setting.icon))])],1),_c('v-list-item-title',[_vm._v(_vm._s(setting.title))])],1):_vm._e()]})],2)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }